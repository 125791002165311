import { useAppSelector } from '@root/store'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import { LinkWrapper } from '../../_links/link-wrapper'
import { useRouter } from 'next/router'
import { VIDZING_EXPLORE_PAGE } from '@root/constants'
import fbAuth from '@apis/firebase'
import RenderIf from '@root/utils/render-if'
import styles from './styles.module.scss'
import { ReactElement, useMemo } from 'react'
import Button from '@ui/cta/button'
import Hyperlink from '@ui/cta/hyperlink'
import FadeInWrapper from '@ui/motion/fade-in-wrapper'

export interface Props {
  userEmail?: string
  channelPathname: string
  requestEmail: string
}

export default function ChannelAccessDenied({
  userEmail,
  channelPathname,
  requestEmail,
}: Props): ReactElement {
  const router = useRouter()
  const channelDisplayName =
    useAppSelector(selectChannelPropByChannelPathname(channelPathname)('displayName')) ?? ''

  // Add back route
  const handleBack = () => {
    // Route to home
    router.push(VIDZING_EXPLORE_PAGE)
  }

  const email = useMemo(() => {
    if (userEmail) {
      return userEmail
    } else {
      return fbAuth.currentUser.email ?? 'undefined'
    }
  }, [userEmail])
  return (
    <FadeInWrapper className={styles.accessDenied}>
      <div className={styles.wrapper}>
        <h1>Oops!</h1>
        <img src="/sad-error.svg" alt="access denied" />
        <p>
          Your email <strong>{email}</strong> doesn&apos;t have access to{' '}
          <strong>{channelDisplayName}.</strong>
        </p>
        <RenderIf isTrue={requestEmail !== ''}>
          <LinkWrapper
            href={`mailto:${requestEmail}?subject=${channelDisplayName} access request`}
            className={styles.link}
          >
            Request Access
          </LinkWrapper>
        </RenderIf>
        <Hyperlink
          label={'Back'}
          variant={'primary'}
          path={VIDZING_EXPLORE_PAGE}
          external={false}
          className={styles.button}
        />
      </div>
    </FadeInWrapper>
  )
}

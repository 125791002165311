import { ChannelSeoInterface } from '@shared/interfaces/layout/seo.interface'
import useFavicon from '@hooks/_theme/use-favicon'
import { APIDETAILS } from '@apis/_utilities/environment/api.url.details'
import useWhiteLabel from '@hooks/_white-label/use-white-label'
import { VIDZING_MARKET_PLACE } from '@shared/constants/root.constants'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'

/**
 * SSR Hook, please Don't include anything from redux here.
 * @param channel
 */
export default function useChannelSeoData(channel: ChannelInterface) {
  // Favicon to use.
  const favicon = useFavicon(channel)

  /*
   is white label?
   I'm not getting this from the selectors because
   this information is coming from getStaticProps into the pages
   */
  const { isWhiteLabel, whiteLabel } = useWhiteLabel(channel)

  // Seo Title
  const title = `${channel.ogMetaTitle || channel.displayName} ${
    isWhiteLabel ? '' : `| ${VIDZING_MARKET_PLACE}`
  }`

  // Url to use
  const url =
    isWhiteLabel && whiteLabel?.customDomain
      ? `${whiteLabel.customDomain}/${channel.channelUrl}`
      : `${APIDETAILS.webDomain}/${channel.channelUrl}`

  // Description
  const description = channel.ogMetaDescription || channel.description

  // Share image
  const shareImage = channel.ogMetaImage || channel.bannerImage
  const shareImageAlt = channel.ogMetaImageAlt || channel.displayName

  // Index
  const canBeIndexed = channel.channelPrivacyType !== 'public'

  // Organisation marketing
  const organisationMarketing = channel.organisation?.organisationMarketingTracking

  return {
    favicon,
    title,
    url,
    description,
    shareImage,
    shareImageAlt,
    canBeIndexed,
    organisationMarketing,
  }
}

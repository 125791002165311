import { WhiteLabelInterface } from '@shared/interfaces/creators/white-label.interface'
import { useAppSelector as useSel } from '@root/store'
import {
  selectChannelPropByChannelPathname,
  selectChannelThemeByChannelPathname,
} from '@store/channel-slice/_selectors'
import { useRouter } from 'next/router'
import { routerProps } from 'hooks/white-label/use-select-white-label-data'

type UseSetBasicCustomDataProps = {
  channelPathname: string
  whiteLabel?: WhiteLabelInterface
}
export default function UseSetBasicCustomData({
  whiteLabel,
  channelPathname,
}: UseSetBasicCustomDataProps) {
  const router = useRouter()
  const { channel_pathname }: routerProps = router.query
  const path = (channel_pathname && `/${channel_pathname}`) || ''
  const channelTheme = useSel(selectChannelThemeByChannelPathname(channelPathname))
  const name = useSel(selectChannelPropByChannelPathname(channelPathname)('displayName')) || ''

  // Set theme to 'dark' or 'light'
  const theme = 'dark'

  return {
    name,
    path,
    fontFormColour: channelTheme?.buttonFontColour || '#fff',
    inputBackgroundColour: '#fff',
    logoWhite: '',
    mainColour: channelTheme?.buttonColour || '#fff',
    rightImage: whiteLabel?.backgroundImageUrl || '',
    secondaryColour: channelTheme?.buttonFontColour || '#fff',
    rightColumnTitle: '',
    rightColumnDesc: '',
    rightColumnColourText: '',
    playerBackgroundColour: '',
    customPlayerContentId: '',
    signUpDisclaimer: whiteLabel?.signUpDisclaimer || '',
    signUpButtonText: 'Confirm',
    passwordValidatorFontColour: '#fff',
    themeColour: theme === 'dark' ? '#000' : '#fff',
    themeContrastColour: theme === 'dark' ? '#fff' : '#000',
    greyThemeColour: theme === 'dark' ? 'var(--grey15)' : 'var(--grey50)',
    backgroundColour: theme === 'dark' ? '#000' : '#fff',
    logo: whiteLabel?.logoUrl || '',
    backgroundImageUrl: whiteLabel?.backgroundImageUrl || '',
    promoVideoUrl: whiteLabel?.promoVideoUrl || '',
  }
}

import {
  setLoadingStatus,
  setSearchText,
  toggleInputVisibility,
  toggleLoading,
} from '@store/search-slice'
import { useAppDispatch, useAppSelector } from '@root/store'
import {
  selectInputVisibility,
  selectIsLoading,
  selectSearchData,
  selectSearchText,
} from '@store/search-slice/_selectors'
import { checkIfString } from '@utils/type-guards/check-if-string'
import { useRouter } from 'next/router'
import useChannelSearch from '@hooks/search/use-channel-search'

export default function useSearchInUrl(channelPathname: string) {
  const dispatch = useAppDispatch()
  const router = useRouter()

  // Search Data
  const inputVisibility = useAppSelector(selectInputVisibility)
  const searchTextRedux = useAppSelector(selectSearchText)
  const searchTextURL = router?.query?.search ?? ''
  const searchCheck = searchTextURL && checkIfString(searchTextURL)
  const searchData = useAppSelector((state) =>
    selectSearchData(
      state,
      searchCheck
        ? { searchText: searchTextURL, channelPathname }
        : { searchText: '', channelPathname },
    ),
  )

  const { setSearchValue, triggerSearch } = useChannelSearch()

  const checkSearchParam = () => {
    if (searchCheck) {
      // Check if redux already has the search text
      if (searchTextRedux === searchTextURL && searchData) {
        dispatch(setLoadingStatus(false))
      } else {
        // If search text is not in redux set the search text
        dispatch(setSearchText(searchTextURL))
        triggerSearch(searchTextURL)
        dispatch(setLoadingStatus(true))
        if (!inputVisibility) dispatch(toggleInputVisibility())
      }
    }
  }

  return { checkSearchParam }
}

import Head from 'next/head'
import { buildThumbnailImageToUse, isImageLocal } from '@utils/image'
import { APIDETAILS } from '@apis/_utilities/environment/api.url.details'
import { OrganisationMarketingTrackingInterface } from '@shared/interfaces/creators/organisation-marketing-tracking.interface'
import useFacebookPixel from '@hooks/use-facebook-pixel'
import useGoogleTagManager from '@hooks/use-google-tag-manager'
import { ReactElement } from 'react'
import { VIDZING_FAVICON } from '@shared/constants/root.constants'

export type Props = {
  title: string
  description: string
  imagePathname: string
  imageAlt: string
  url: string
  organisationMarketingTracking?: OrganisationMarketingTrackingInterface
  noIndex?: boolean
  keywords?: string
  favicon?: string
}

export function HtmlHead({
  title,
  description,
  imagePathname,
  imageAlt,
  url: urlString,
  organisationMarketingTracking,
  noIndex,
  keywords,
  favicon = VIDZING_FAVICON,
}: Props): ReactElement {
  const urlObj = new URL(urlString)
  const domain = `${urlObj.protocol}//${urlObj.host}`

  // Initialise the organisations google tag manager if present
  useGoogleTagManager(organisationMarketingTracking?.gtmId)

  // Use facebook pixel hook to initialise the pixel if present
  useFacebookPixel(organisationMarketingTracking?.fbPixelId)

  /**
   * Build the image from the image pathname
   * If the image pathname exists check if its local & if so attach to the vidzing domain
   * Otherwise the image pathname will be a url from cloudinary or mux so use the build thumbnail to
   * optimise for seo
   */
  let imgSrc: string
  if (imagePathname) {
    if (isImageLocal(imagePathname)) {
      imgSrc = `${APIDETAILS.webDomain}${imagePathname}`
    } else {
      imgSrc = buildThumbnailImageToUse(imagePathname, 1200, '1.91:1')
    }
  }

  return (
    <Head>
      <title key="title">{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={imgSrc} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={urlString} />
      <meta property="og:type" content="video.other" />
      <meta property="article:publisher" content={domain} />
      <meta property="article:section" content="Media Streaming" />
      <meta property="article:tag" content="Media Streaming" />
      <meta property="fb:app_id" content="736370953696061" />
      <meta property="og:image" content={imgSrc} />
      <meta property="og:image:secure_url" content={imgSrc} />
      <meta property="og:image:alt" content={imageAlt} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:image" content={imgSrc} />
      <meta name="twitter:image:alt" content={imageAlt} />
      <link rel="icon" href={favicon} />
      {noIndex && <meta name="robots" content="noindex" />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Head>
  )
}

import { useRouter } from 'next/router'
import splitChannelUrl from '@root/utils/split-channel-url'
import getCustomChannelData from '@root/utils/get-custom-channel-data'

export default function UseIsCustomChannel() {
  const router = useRouter()
  const path = router.asPath
  const { channelSlug } = splitChannelUrl(path)
  return getCustomChannelData(channelSlug)
}

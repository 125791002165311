import { useEffect } from 'react'
import { useRouter } from 'next/router'
import isFacebookPixelLoaded from '@utils/is-facebook-pxiel-loaded'

export default function useFacebookPixel(fbPixelId?: string) {
  const router = useRouter()

  useEffect(() => {
    // If the pixel id is defined and the pixel is not loaded, load the pixel
    if (fbPixelId && !isFacebookPixelLoaded(fbPixelId)) {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(fbPixelId)
          ReactPixel.pageView()

          // On route change trigger page view event
          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView()
          })
        })
    }
  }, [fbPixelId, router.events])
}

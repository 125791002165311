import { useEffect } from 'react'
import { getChannelThunk } from '@store/channel-slice'
import { useAppDispatch, useAppSelector as useSel } from '@root/store'
import { fetchOrganisationByOrgId } from '@store/organisation-slice/_thunks'
import UseSetBasicCustomData from '@hooks/use-set-basic-custom-data'
import { setCustomChannel } from '@store/custom-channel'
import { selectCustomChannel } from '@store/custom-channel/_selector'
import UseSelectWhiteLabelData from 'hooks/white-label/use-select-white-label-data'
import UseIsCustomChannel from '@hooks/use-is-custom-channel'

interface UseHasWhiteLabelProps {
  fetchChannel?: boolean
  fetchOrganisation?: boolean
}

export default function UseHasWhiteLabel({
  fetchChannel = true,
  fetchOrganisation = true,
}: UseHasWhiteLabelProps) {
  // Check if custom channel
  const customChannelData = UseIsCustomChannel()
  const isCustomChannel = !!customChannelData

  const dispatch = useAppDispatch()
  const { whiteLabel, channelName, channelId, organisationIdFromChannel } =
    UseSelectWhiteLabelData()
  const basicCustomPageData = UseSetBasicCustomData({ whiteLabel, channelPathname: channelName })
  const reduxCustomChannel = useSel(selectCustomChannel)

  // Fetch the channel if it is not present
  useEffect(() => {
    if (fetchChannel && channelName && !channelId) {
      dispatch(getChannelThunk(channelName))
    }
  }, [channelId, channelName, dispatch, isCustomChannel, fetchChannel])

  // Get the organisation from the channel
  // Check if whiteLabel is missing and is not a custom channel
  // Fetch the org details if whiteLabel is missing
  useEffect(() => {
    if (fetchOrganisation && organisationIdFromChannel && whiteLabel === undefined) {
      dispatch(fetchOrganisationByOrgId(organisationIdFromChannel))
    }
  }, [organisationIdFromChannel, dispatch, whiteLabel, isCustomChannel, fetchOrganisation])

  // Check if not a custom channel and has a whiteLabel
  // If the custom channel stored in redux is missing or is not the same as the one being loaded,
  // Set the custom channel data
  useEffect(() => {
    if (
      !isCustomChannel &&
      whiteLabel &&
      (!reduxCustomChannel || reduxCustomChannel.path !== `/${channelName}`) &&
      basicCustomPageData
    ) {
      dispatch(setCustomChannel(basicCustomPageData))
    }
  }, [whiteLabel, dispatch, isCustomChannel, basicCustomPageData, reduxCustomChannel, channelName])

  // Custom channel automatically loads the data
  // White label data is either null or white label object when loaded
  const dataLoaded = isCustomChannel ? true : whiteLabel !== undefined

  return { whiteLabel, dataLoaded, isCustomChannel }
}

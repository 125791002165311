import { useEffect } from 'react'
import { isGtmInitialised } from '@utils/is-gtm-loaded'
import TagManager from 'react-gtm-module'

export default function useGoogleTagManager(gtmId?: string) {
  useEffect(() => {
    if (gtmId && !isGtmInitialised()) {
      TagManager.initialize({
        gtmId,
      })
    }
  }, [gtmId])
}

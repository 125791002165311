import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { hasProp } from '@utils/has-prop'
import { VIDZING_FAVICON } from '@shared/constants/root.constants'

/**
 * Return favicon to use
 * @param channel
 */
export default function useFavicon(channel: ChannelInterface) {
  const whiteLabel = channel?.organisation?.whiteLabel
  const isWhiteLabel = !!whiteLabel
  return isWhiteLabel && hasProp(whiteLabel?.faviconUrl) ? whiteLabel?.faviconUrl : VIDZING_FAVICON
}
